import PropTypes from "prop-types";
import React, {Fragment, useEffect} from "react";

import "./styles.css";

DropdownButton.propTypes = {
  index: PropTypes.number,
  statusIndex: PropTypes.objectOf(PropTypes.bool),
  id: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    nickname: PropTypes.string,
    description: PropTypes.string,
    attendee_name: PropTypes.string,
    attendee_lastname: PropTypes.string,
    receiver_mail: PropTypes.string,
    amount: PropTypes.number,
    enabled: PropTypes.number,
    event_sector_lot_id: PropTypes.number,
    pdv_id: PropTypes.number,
    created_at: PropTypes.string,
    printed_at: PropTypes.string,
    lot_generated: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    order: PropTypes.shape({
      token_ticket: PropTypes.string
    }),
    event_sector_lot: PropTypes.shape({
      event_sector: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  onMouseLeave: PropTypes.func,
  status: PropTypes.bool,
  itensAmount: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func,
      children: PropTypes.node,
      params: PropTypes.node,
    })
  ),
};

function OptionButtons({id, options, onMouseLeave, handlePosition, handleClick}){

  useEffect(() => {
    function handleClickOutside(event) {
      const dropdownElement = document.getElementById(`dropdown-${id}`);
      if (dropdownElement && !dropdownElement.contains(event.target)) {
        onMouseLeave();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [id, onMouseLeave]);

  return(
    <div
      onMouseLeave={() => onMouseLeave()}
      id={`dropdown-${id}`}
      className={`modal-options-container ${handlePosition() ? "last-item" : ""}`}
    >
      {options?.map((option, index) => (
        <button
          key={index}
          type="button"
          id="dropModal"
          onClick={() => handleClick(option)}
          className="dropdown-item"
        >
          {option?.children}
        </button>
      ))}
    </div>
  )
}

export default function DropdownButton({
  id,
  data,
  onMouseLeave,
  options,
  element,
  status,
  statusIndex,
  itensAmount,
  index,
}) {
  const handleClick = (option) => {
    const args = option.directParams ? option.directParams : option.params.map((param) => data[param]);
    option.callback(...args);
  };

  const handlePosition = () => {
    return itensAmount-1 === index
  };
  
  return (
    <Fragment>
      {element}
      {(status || statusIndex[id]) && (
        <OptionButtons id={id} options={options} onMouseLeave={onMouseLeave} handlePosition={handlePosition} handleClick={handleClick}/>
      )}
    </Fragment>
  );
}
