import React from "react";
import PropTypes from "prop-types";
import { Container, TableContainer } from "./styles";
import { ToolTip } from "components/tooltip";
import { useTranslation } from "react-i18next";
import { NewToolTip } from "components/NewToolTip";

Table.prototype = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      colSpan: PropTypes.number.isRequired,
      align: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.object,
};

const getFlexJustify = (alignText) => {
  switch (alignText) {
    case "center":
      return "flex-center";
    case "left":
      return "flex-start";
    default:
      return "flex-end";
  }
};

export default function Table({
  title,
  columns,
  rows,
  total,
  headerUpperCase,
  footerBackground,
}) {
  const { t } = useTranslation("tickets");
  return (
    <Container>
      <h3>{title}</h3>
      <TableContainer>
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => {
                const { tooltip, ...columnInfo } = column;
                const title = headerUpperCase
                  ? columnInfo.title?.toUpperCase()
                  : columnInfo.title;
                return (
                  <th
                    key={index}
                    colSpan={columnInfo.colSpan}
                    style={{
                      textAlign: columnInfo.align,
                    }}
                  >
                    {tooltip ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: getFlexJustify(columnInfo.align),
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        {title}
                        {tooltip && <NewToolTip tooltip={tooltip} />}
                      </div>
                    ) : (
                      <>{title}</>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr className="empty-row">
                <td
                  colSpan={columns.reduce(
                    (acc, column) => acc + column.colSpan,
                    0
                  )}
                >
                  {t("financialStatement.noData")}
                </td>
              </tr>
            ) : (
              rows.map((row, index) => {
                const { isHeader, tooltip, ...rowInfo } = row;
                const values = Object.values(rowInfo);
                return (
                  <tr key={index}>
                    {values.map((value, index) => {
                      if (typeof value === "function") {
                        return (
                          <td
                            key={index}
                            colSpan={columns[index].colSpan}
                            style={{ textAlign: columns[index].align }}
                          >
                            {value()}
                          </td>
                        );
                      }
                      return (
                        <td
                          key={index}
                          colSpan={columns[index].colSpan}
                          style={{ textAlign: columns[index].align }}
                          className={isHeader ? "header-cell" : "table-cell"}
                        >
                          {value}
                          {index === 0 && tooltip && (
                            <ToolTip
                              tooltip={tooltip}
                              classes={"tooltip-absolute"}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={columns[0].colSpan}
                style={{
                  textAlign: columns[0].align,
                  fontSize: "14px",
                  background: footerBackground ? footerBackground : undefined,
                }}
                className="table-footer"
              >
                Total
              </td>
              {Object.values(total).map((value, index) => {
                return (
                  <td
                    key={index + 1}
                    colSpan={columns[index + 1].colSpan}
                    style={{
                      textAlign: columns[index + 1].align,
                      fontSize: "14px",
                      background: footerBackground ? footerBackground : undefined,
                    }}
                    className="table-footer"
                  >
                    {value}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </TableContainer>
    </Container>
  );
}
