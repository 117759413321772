import { formatCurrency } from "utils";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/date-formatter";
import { finalityKeys } from "services/constants";

export const useBorderoTransfer = ({ currency, handleEdit, handleDelete }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");
  const formatDate = (date) =>
    dateFormatter(new Date(date), { locale: language });
  const centsCurrencyFormatter = (value) =>
    formatCurrency(value, {
      isCents: true,
      currency: currency,
    });

  const transferHeaderTranslation = {
    totalValueTransfersCompleted: t(
      "financialStatement.totalValueTransfersCompleted"
    ),
    totalValueTransfersPending: t(
      "financialStatement.totalValueTransfersPending"
    ),
    totalWithdrawalAvailable: t("financialStatement.totalWithdrawalAvailable"),
  };

  const transferHelpers = {
    mapHeader: (data) =>
      Object.entries(data).map(([key, value]) => {
        return {
          id: key,
          amount: centsCurrencyFormatter(value),
          description: transferHeaderTranslation[key],
        };
      }),
    mapTables: (data, total) => {
      const rows = data.map((transfer) => {
        return {
          type: finalityKeys[transfer.finality],
          requester: transfer.requester,
          beneficiary: transfer.recipient,
          requestDate: formatDate(new Date(transfer.requestDate)),
          paymentDate: formatDate(new Date(transfer.paymentDate)),
          value: centsCurrencyFormatter(transfer.amount),
        };
      });
      return {
        tables: [
          {
            columns: [
              {
                title: t("financialStatement.identification"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.requester"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.beneficiary"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.paymentDate"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.requestDate"),
                colSpan: 1,
                align: "left",
              },
              {
                title: t("financialStatement.receivedValue"),
                colSpan: 1,
                align: "right",
              },
            ],
            rows,
            total: {
              empty: "",
              emptyOne: "",
              emptyTwo: "",
              emptyThree: "",
              total: centsCurrencyFormatter(total),
            },
          },
        ],
      };
    },
  };
  return {
    transferHelpers,
  };
};
