import React from "react";
import { ToolTip } from "components/tooltip";
import { Container, Content } from "./styles";
import { NewToolTip } from "components/NewToolTip";

export const BorderoCard = ({
  amount,
  description,
  tooltip,
  isTransferPage = false,
  className,
}) => {
  return (
    <Container isTransferPage={isTransferPage} className={className}>
      {isTransferPage ? (
        <>
          <div className="tp-container">
            {description}
            {tooltip && (
              <ToolTip classes="tooltip-absolute" tooltip={tooltip} />
            )}
          </div>
          <h3>{amount}</h3>
        </>
      ) : (
        <Content>
          <div className="amount-container">
            <h3>{amount}</h3>
            {tooltip && <NewToolTip tooltip={tooltip} />}
          </div>
          <>{description}</>
        </Content>
      )}
    </Container>
  );
};
