import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils";

export const useBorderoSales = ({ currency }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("tickets");
  const centsCurrencyFormatter = (value) =>
    formatCurrency(value, {
      isCents: true,
      currency: currency,
    });

    const salesHeaderTranslation = {
      totalSalesRevenue: t("financialStatement.totalSalesRevenue"),
      totalParticipants: t("financialStatement.ticketsEmitted"),
      checkin: "%" + t("financialStatement.checkin"),
      tkm: t("financialStatement.tkm"),
      totalOrders: t("financialStatement.totalOrders"),
      paidTickets: t("financialStatement.paidTickets"),
      freeTickets: t("financialStatement.freeTickets"),
    };
  const salesFormatter = {
    totalSalesRevenue: (val) => centsCurrencyFormatter(val),
    totalParticipants: (val) => val.toLocaleString(language),
    checkin: (val, checkInParticipation) =>
      `${val} (${checkInParticipation}%)`,
    tkm: (val) => centsCurrencyFormatter(val),
    totalOrders: (val) => val.toLocaleString(language),
  };
  const salesTooltip = {
    totalSalesRevenue: t("financialStatement.totalValueOfTicketsSold"),
    totalParticipants: t(
      "financialStatement.totalNumberOfTicketsSoldToolTip"
    ),
    checkin: t("financialStatement.checkinToolTip"),
    tkm: t("financialStatement.tkmToolTip"),
    totalOrders: t("financialStatement.totalOrdersToolTip"),
    serviceFeeRebateToolTip: t("financialStatement.serviceFeeRebateToolTip"),
    processingFeeRebateToolTip: t(
      "financialStatement.processingFeeRebateToolTip"
    ),
  };

  const salesHelper = {
    mapHeader: (data) => {
      const mapOrder = {
        totalSalesRevenue: data.totalSalesRevenue,
        totalOrders: data.totalOrders,
        tkm: data.tkm,
        totalParticipants: data.totalParticipants,
        paidTickets: data.paidTickets,
        freeTickets: data.freeTickets,
      };
      return Object.entries(mapOrder)
        .map(([key, value]) => {
          if (key === "checkinParticipation") return undefined;
          const formatter = salesFormatter[key];
          const checkInParticipation = mapOrder.checkinParticipation
            ? mapOrder.checkinParticipation.toFixed(2)
            : 0;
          const amount = formatter
            ? formatter(value, checkInParticipation)
            : value;
          return {
            id: key,
            amount,
            description: salesHeaderTranslation[key],
            tooltip: salesTooltip[key],
          };
        })

        .filter(Boolean);
    },

    mapTables: (data) => {
      const byTickets = salesHelper.mapByTicketType(
        data.salesDataByTicketType
      );
      console.log("byTickets:", byTickets);
      const byLotTable = salesHelper.mapByLot(data.salesDataByLot);
      console.log("byLotTable:", byLotTable);
      const bySectorTable = salesHelper.mapBySector(data.salesDataBySector);
      const byPaymentMethods = salesHelper.mapByPaymentMethods(
        data.salesDataByPaymentMethods
      );
      const byPaymentMethodsOrigin = salesHelper.mapByPaymentMethodsOrigin(
        data.salesDataByPaymentOrigin
      );
      const otherRevenues = {
        columns: [
          {
            title: t("financialStatement.identification"),
            colSpan: 10,
            align: "left",
          },
          {
            title: t("financialStatement.receivedValue"),
            colSpan: 2,
            align: "right",
          },
        ],
        rows: [
          {
            name: t("financialStatement.serviceFeeRebate"),
            value: centsCurrencyFormatter(
              data.otherRevenues.serviceFeeRebait
            ),
            tooltip: salesTooltip["serviceFeeRebateToolTip"],
          },
          {
            name: t("financialStatement.processingFeeRebate"),
            value: centsCurrencyFormatter(
              data.otherRevenues.processingFeeRebait
            ),
            tooltip: salesTooltip["processingFeeRebateToolTip"],
          },
        ],
        total: {
          total: centsCurrencyFormatter(data.otherRevenues.totals),
        },
      };
      return {
        tables: [
          byTickets,
          bySectorTable,
          byLotTable,
          byPaymentMethodsOrigin,
          byPaymentMethods,
          otherRevenues,
        ],
      };
    },
    mapByLot: (data) => {
      const rows = data.lots.reduce((acc, lot) => {
        acc.push({
          lot: lot.name,
          sold: lot.soldQty.toLocaleString("pt-BR"),
          participation: `${lot.participation.toFixed(2)}%`,
          checkIn: `${lot.checkin} (${lot.checkinPercentage.toFixed(2)}%)`,
          total: centsCurrencyFormatter(lot.total),
          isHeader: true,
        });

        acc.push(
          ...lot.lines.map((lot) => ({
            lot: lot.name,
            sold: lot.amount,
            participation: `${lot.participation.toFixed(2)}%`,
            checkIn: `${lot.checkin} (${lot.checkinPercentage.toFixed(2)}%)`,
            total: centsCurrencyFormatter(lot.total),
          }))
        );
        return acc;
      }, []);

      return {
        columns: [
          {
            title: t("financialStatement.lot"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.sold"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participationLot"),
            colSpan: 1,
            align: "right",
            tooltip: t("financialStatement.participationLotToolTip"),
          },
          {
            title: t("financialStatement.checkin").toUpperCase() + " (%)",
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          sold: data.total.soldQty.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkin
          } (${data.total.checkinPercentage.toFixed(2)}%)`,
          total: centsCurrencyFormatter(data.total.total),
        },
      };
    },
    mapBySector: (data) => {
      const rows = data.sectors.reduce((acc, sector) => {
        acc.push({
          sector: sector.name,
          quantity: sector.totalSold.toLocaleString(language),
          participation: `${sector.participation.toFixed(2)}%`,
          checkIn: `${sector.checkin} (${sector.checkinPercentage.toFixed(
            2
          )}%)`,
          total: centsCurrencyFormatter(sector.total),
          isHeader: true,
        });
        acc.push(
          ...sector.lines.map(
            ({
              name,
              amount,
              participation,
              checkin,
              checkinPercentage,
              total,
            }) => {
              return {
                sector: name,
                quantity: amount,
                participation: `${participation.toFixed(2)}%`,
                checkIn: `${checkin} (${checkinPercentage.toFixed(2)}%)`,
                total: centsCurrencyFormatter(total),
              };
            }
          )
        );
        return acc;
      }, []);

      return {
        columns: [
          {
            title: t("financialStatement.sector"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.sold"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
            tooltip: t("financialStatement.participationtToolTip"),
          },
          {
            title: t("financialStatement.checkin").toUpperCase() + " (%)",
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          quantity: data.total.totalSold.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkin
          } (${data.total.checkinPercentage.toFixed(2)}%)`,
          total: centsCurrencyFormatter(data.total.total),
        },
      };
    },

    mapByPaymentMethods: (data) => {
      const rows = data.paymentMethods.map((paymentMethod) => {
        return {
          paymentMethod: paymentMethod.paymentType,
          total: centsCurrencyFormatter(paymentMethod.totalValue),
          quantity: paymentMethod.amount.toLocaleString(language),
          participation: `${paymentMethod.participation.toFixed(2)}%`,
          checkIn: `${
            paymentMethod.checkIn
          } (${paymentMethod.checkInPercentage.toFixed(2)}%)`,
        };
      });

      return {
        columns: [
          {
            title: t("financialStatement.paymentMethod"),
            colSpan: 4,
            align: "left",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.qty"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participation"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.checkin").toUpperCase(),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          total: centsCurrencyFormatter(data.total.totalValue),
          quantity: data.total.amount.toLocaleString(language),
          participation: `${data.total.participation.toFixed(2)}%`,
          checkIn: `${
            data.total.checkIn
          } (${data.total.checkInPercentage.toFixed(2)}%)`,
        },
      };
    },
    mapByTicketType: (data) => {
      const rows = data.tickets.reduce((acc, ticket) => {
        acc.push({
          name: t(`financialStatement.${ticket.type}`),
          soldTickets: ticket.soldTickets,
          soldPercent: `${(ticket.soldPercent ?? 0).toFixed(2)}%`,
          checkIn: `${ticket.checkin} (${ticket.checkingPercentage.toFixed(
            2
          )}%)`,
          totalPaid: centsCurrencyFormatter(ticket.totalPaid),
          isHeader: true,
        });
        acc.push(
          ...ticket.lines.map((ticket) => ({
            name: ticket.ticketType,
            soldTickets: ticket.ticketAmount,
            soldPercent: `${(ticket.soldPercent ?? 0).toFixed(2)}%`,
            checkIn: `${ticket.checkin} (${ticket.checkinPercentage.toFixed(
              2
            )}%)`,
            totalPaid: centsCurrencyFormatter(ticket.paid),
          }))
        );

        return acc;
      }, []);
      return {
        total: {
          soldTickets: data.totals.totalSoldTickets,
          soldPercent: "100%",
          checkin: `${data.totals.totalCheckin} (${(
            data.totals.totalCheckinPercentage ?? 0
          ).toFixed(2)}%)`,
          totalPaid: centsCurrencyFormatter(data.totals.totalPaid),
        },
        rows,
        columns: [
          {
            title: t("financialStatement.byTicketType"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.byTicketType"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.percentByType"),
            colSpan: 1,
            align: "right",
            tooltip: t("financialStatement.byTypeTooltip"),
          },
          {
            title: t("financialStatement.checkin").toUpperCase() + " (%)",
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.total").toUpperCase(),
            colSpan: 1,
            align: "right",
          },
        ],
      };
    },
    mapByPaymentMethodsOrigin: (apiData) => {
      const { origins, totals } = apiData;
      const rows = origins.reduce((acc, origin) => {
        acc.push({
          name: origin.origin,
          amount: origin.amount,
          participation: `${origin.participation?.toFixed(2)}%`,
          checkIn: `${origin.checkin} (${origin.checkinPercentage?.toFixed(
            2
          )}%)`,
          total: centsCurrencyFormatter(origin.total),
          isHeader: true,
        });

        acc.push(
          ...origin.lines.map(
            ({
              paymentType,
              totalValue,
              amount,
              participation,
              checkin,
              checkinPercentage,
            }) => ({
              name: paymentType,
              amount,
              participation: `${participation.toFixed(2)}%`,
              checkIn: `${checkin} (${checkinPercentage.toFixed(2)}%)`,
              total: centsCurrencyFormatter(totalValue),
            })
          )
        );
        return acc;
      }, []);

      return {
        columns: [
          {
            title: t("financialStatement.paymentMethodOrigin"),
            colSpan: 2,
            align: "left",
          },
          {
            title: t("financialStatement.sold"),
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.participationByPayment"),
            colSpan: 1,
            align: "right",
            tooltip: t("financialStatement.participationByPaymentToolTip"),
          },
          {
            title: t("financialStatement.checkin").toUpperCase() + " (%)",
            colSpan: 1,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 1,
            align: "right",
          },
        ],
        rows,
        total: {
          amount: totals.amount,
          participation: `${totals.participation.toFixed(2)}%`,
          checkIn: `${totals.checkin} (${totals.checkinPercentage.toFixed(
            2
          )}%)`,
          total: centsCurrencyFormatter(totals.total),
        },
      };
    },
  };


  return {
    salesHelper,
  };
};
