import React, { useRef, useState, useEffect } from "react";
import { ToolTipContainer } from "./styles";
import { ReactComponent as InfoTool } from "../../assets/icons/infoTool.svg";

export const NewToolTip = ({ tooltip }) => {
  const [isNearLeft, setIsNearLeft] = useState(false);
  const toolTipRef = useRef(null);
  useEffect(() => {
    const handlePosition = () => {
      if (toolTipRef.current) {
        const bounding = toolTipRef.current.getBoundingClientRect();
        const screenWidth = window.innerWidth;
        const isLeft = bounding.left < screenWidth * 0.25;
        setIsNearLeft(isLeft);
      }
    };
    handlePosition();
    window.addEventListener("resize", handlePosition);
    return () => {
      window.removeEventListener("resize", handlePosition);
    };
  }, []);
        
  return (
    <ToolTipContainer isNearLeft={isNearLeft} ref={toolTipRef}>
      <InfoTool width="1rem" height="1rem" />
      <div className="tip-data">{tooltip}</div>
    </ToolTipContainer>
  );
};
