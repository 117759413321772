import React, { useEffect, useRef, useState } from "react";
import { Container, Content, Header } from "./styles";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";

/**
 * @param {Object} props
 * @param {String} props.title - Title of the collapsible container
 * @param {React.ReactNode} props.icon - Icon to be displayed on the left of the title
 * @param {React.ReactNode} props.children - Content to be displayed when the container is expanded
 */
export const CollapsibleContainer = ({ title, icon, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      // Access the height of the element
      const height = contentRef.current.offsetHeight;
      setContentHeight(height);
    }
  }, []);
  return (
    <Container isCollapsed={isCollapsed} contentHeight={contentHeight}>
      <Header>
        <div>
          {icon}
          <h1>{title}</h1>
        </div>
        <div
          className="collapsbile-icon-container"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {!isCollapsed ? (
            <ChevronUp stroke="#0050C3" strokeWidth={2} />
          ) : (
            <ChevronDown stroke="#0050C3" strokeWidth={2} />
          )}
        </div>
      </Header>
      <Content ref={contentRef} isCollapsed={isCollapsed} >{children}</Content>
    </Container>
  );
};
