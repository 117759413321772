import financialApi from "services/financial-api";

export const fetchDeductions = async (eventId, dateParams) => {
  const response = await financialApi
    .get(`/bordero/deductions?eventId=${eventId}${dateParams}`)
    .then((res) => res.data);
  return response;
};

export const fetchTransfers = async (eventId, dateParams) => {
  const response = await financialApi
    .get(`/bordero/transfers?eventId=${eventId}${dateParams}`)
    .then((res) => res.data);
  return response;
};

export const fetchSales = async (eventId, dateParams) => {
  const response = await financialApi
    .get(`/bordero/sales?eventId=${eventId}${dateParams}`)
    .then((res) => res.data);
  return response;
};

