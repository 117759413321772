import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  padding: 0 1rem;
  box-sizing: border-box;
  border: 1px solid #d3d2d9;
  border-radius: 8px;
  background: #fff;
  min-height: 78px;
  height: ${({ isCollapsed, contentHeight }) =>
    isCollapsed ? "78px" : `${contentHeight + 78}px`};
  transition: height 0.1s ease-in-out;
 `;

export const Content = styled.div`
  opacity: ${({ isCollapsed }) => (isCollapsed ? "0" : 1)};
  visibility: ${({ isCollapsed }) => (isCollapsed ? 'hidden' : 'visible')};;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 78px;
  justify-content: space-between;
  width: 100%;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  h1 {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    color: #000;
    font-family: "Lato", sans-serif;
  }
  .collapsbile-icon-container {
    cursor: pointer;
  }
`;
