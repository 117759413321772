import {
  faClone,
  faPen,
  faSpinner,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastr";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import XLSX from "xlsx";

import Pagination from "components/Pagination";
import IngressoHeader from "components/subheader";
import api from "services/api";
import { paginationConfigs } from "services/constants";

import ModalComissarios from "./modalComissarios";
import Loading from "components/loading";
import EmptyData from "components/empty-data";
import { capitalizeFirstWord } from "utils/str-format";
import New_api from "services/new-api";
import DropdownButton from "components/dropdownButton";
import { ReactComponent as Copy } from "../../../../assets/icons/copy_btn.svg";

let container;

const Comissarios = () => {
  const { t } = useTranslation("tickets");
  const fileInputRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");

  const [data, setData] = useState([]);
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [carrregandoPlanilha, setCarregandoPlanilha] = useState(false);
  const [modalStatus, setModalStatus] = useState("modal-hidden");
  const [comissario_id, setComissario] = useState(null);
  const [reload, setReload] = useState(false);
  const [swalLoading] = useState(`${t("promoters.sheetWarn")} <br/>
  <a class='btn-link' download href='/promoters.xlsx'>${t(
    "global:clickHere"
  ).toUpperCase()}</a> ${t("promoters.toDownload")}`);
  const [pageConfig, setPageConfig] = useState(paginationConfigs);
  const [showOptions, setShowOptions] = useState({ id: false });

  useEffect(() => {
    setIsLoading(true);
    New_api.get(`/events/${event_id}`, {
      params: { fields: ["id", "slug", "link_other", "link_funz", "organization_id"], includes: ["organization"] },
    })
      .then(({ data }) => setEvent(data))
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
    stewardList();
    // eslint-disable-next-line
  }, [event_id, reload]);

  const stewardList = (
    page = paginationConfigs.page,
    perPage = paginationConfigs.perPage
  ) => {
    if (!isLoading) setIsLoading(true);
    api
      .get(`event/stewards/${event_id}`, {
        params: {
          page,
          per_page: perPage,
        },
      })
      .then((res) => {
        setData(res.data.stewards.data);
        setPageConfig({
          page: res.data.stewards.page,
          currentPage: res.data.stewards.page,
          lastPage: res.data.stewards.lastPage,
          amount: res.data.stewards.total,
          perPage: res.data.stewards.perPage,
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      })
      .finally(() => setIsLoading(false));
  };

  function openModal(item) {
    if (item) {
      setComissario(item);
    }
    setModalStatus("modal-show");
  }

  function closeModal(status, isReload) {
    setComissario(null);
    setModalStatus(status);
    if (isReload) {
      setTimeout(() => {
        reload ? setReload(false) : setReload(true);
      }, 600);
    }
  }

  function copyLinkToClipboard(text, steward) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed"; //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      container.success(t("promoters.copyLinkSuccess"));
      setTimeout(() => {
        delete steward["showCopyMsg"];
      }, 1500);
    } catch (err) {
      container.success(t("promoters.copyLinkError"));
      setTimeout(() => {
        delete steward["showCopyErrorMsg"];
      }, 1500);
    }

    document.body.removeChild(textArea);
  }

  async function importarcomissarios() {
    let fileimport = document.getElementById("carregacomissarios");
    fileimport.click();

    fileimport.addEventListener('cancel', () => {
      Swal.enableButtons();
  });

  }

  function uploadStewards(e) {
    Swal.update({
      icon: "",
      title: t("promoters.loading"),
      html: '<i class="fas fa-circle-notch fa-spin fa-7x" style="color: #600EA3"></i>',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    setCarregandoPlanilha(true);
    // let event = event_id ? event_id : this.props.event_id;
    let dados = null;
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    const files = e.target.files;
    let file = files[0];
    
    if (!file) {
      setCarregandoPlanilha(false);
      Swal.close()
      fileInputRef.current.value = "";
      return;
    }

    const validExtension = ".xlsx";
    const fileName = file.name;
    if (!fileName.endsWith(validExtension)) {
      Swal.fire({
        icon: "error",
        title: "Invalid File Type",
        text: `Please upload a file with the extension ${validExtension}.`,
      });
      setCarregandoPlanilha(false);
      fileInputRef.current.value = "";
      return;
    }

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      dados = { retorno: data, event_id: event.id };

      api
        .post("upload/stewards", dados)
        .then(() => {
          Swal.fire({
            title: capitalizeFirstWord(t("global:success")),
            icon: "success",
            text: t("promoters.successCreate"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: t("global:confirmButtonText"),
          })
            .then(() => {
              setCarregandoPlanilha(false);
              reload ? setReload(false) : setReload(true);
            })
            .catch(() => {
              Swal.fire({
                title: "Ops!",
                icon: "error",
                text: t("global:errors.internalServerError"),
                confirmButtonText: t("global:confirmButtonText"),
              });
            });
        })
        .catch(() => {
          setCarregandoPlanilha(false);
          Swal.fire({
            title: t("global:fail"),
            icon: "error",
            text: t("promoters.payAttentionWarn"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#95aac9",
            confirmButtonText: t("global:confirmButtonText"),
          });
        });
    };
    fileInputRef.current.value = "";
  }

  function removeSteward(stewardId) {
    Swal.fire({
      icon: "warning",
      title: t("promoters.sureDeleteWarning"),
      showCancelButton: true,
      confirmButtonText: capitalizeFirstWord(t("global:delete")),
      cancelButtonText: t("global:cancelButtonText"),
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          api
            .delete(`delete/steward/${stewardId}`)
            .then(() => {
              Swal.fire(t("promoters.deletedCommissioner"), "", "success");
              stewardList();
            })
            .catch(() => {
              Swal.fire(t("promoters.notPossibleDeleteWarn"), "", "info");
            });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      });
  }

  const handleStewardModal = (status, steward) => {
    if (steward) {
      setComissario(steward);
    }
    setModalStatus(status);
  };

  const handleCopyLink = (steward, type) => {
    if (type === 'organization') {
      const parsedLink = event?.link_funz.replace('eventos/', '')
      return copyLinkToClipboard(
        event
          ? event.link_other
            ? `${event.link_other}?code=${steward.code}`
            : event.link_funz
            ? `${parsedLink}organizacoes/${event.organization.slug}?code=${steward.code}`
            : ""
          : "",
        steward
      )
    } else {
      return copyLinkToClipboard(
        event
          ? event.link_other
            ? `${event.link_other}?code=${steward.code}`
            : event.link_funz
            ? `${event.link_funz}${event.slug}?code=${steward.code}`
            : ""
          : "",
        steward
      )
    }
  }

  const handleModalOptions = (id) => {
    setShowOptions(() => ({
      [id]: true,
    }));
  };

  const options = (steward) => {
    return [
      {
        children: (
          <Fragment>
            <Copy/>
            {t("promoters.eventLink")}
          </Fragment>
        ),
        callback: handleCopyLink,
        directParams: [steward, "event"]
      },
      {
        children: (
          <Fragment>
            <Copy/>
            {t("promoters.organizationLink")}
          </Fragment>
        ),
        callback: handleCopyLink,
        directParams: [steward, "organization"]
      },
    ];
  };

  return (
    <Fragment>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      {isLoading && <Loading title={`${t("global:wait")}...`} />}
      <div className="page-title container-fluid shadow-sm">
        <div className="col-12 page-header-infos">
          <div className="row">
            <div className="col-md-5">
              <h2>{t("promoters.title")}</h2>
              <p>{t("promoters.subtitle")}</p>
            </div>
            <div className="box-btn col-6 col-md-4">
              <button
                onClick={() => {
                  Swal.fire({
                    title: `<strong>${t(
                      "promoters.importingPromoters"
                    )}</strong>`,
                    icon: "info",
                    html: swalLoading,
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: t("global:cancelButtonText"),
                    confirmButtonText: t("promoters.sendSheet"),
                    closeClick: false,
                    preConfirm: () => {
                      return new Promise(async function (resolve, reject) {
                        await importarcomissarios();
                      });
                    },
                  });
                }}
                disabled={carrregandoPlanilha}
                className={"btn btn-primary c3"}
              >
                {carrregandoPlanilha ? (
                  <FontAwesomeIcon className="infiteSpining" icon={faSpinner} />
                ) : (
                  <Fragment>
                    <FontAwesomeIcon icon={faUpload} />{" "}
                    {t("promoters.importPromoters")}
                  </Fragment>
                )}
              </button>
              <input
                id="carregacomissarios"
                type="file"
                accept=".xlsx"
                style={{ display: "none" }}
                onChange={(e) => uploadStewards(e)}
                ref={fileInputRef}
              />
            </div>
            <div className="col-6 col-md-3">
              <button
                className={"btn btn-primary c3"}
                onClick={() => {
                  openModal();
                }}
              >
                {t("promoters.createPromoter")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <IngressoHeader position={4} evento={event_id} />
      <div className="container-fluid mt-4" style={{ paddingBottom: "80px" }}>
        <div className="card">
          <div className="card-body">
            {data.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-sm card-table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col-3">{t("promoters.name")}</th>
                      <th scope="col-3">{t("promoters.email")}</th>
                      <th className="text-center" scope="col-3">
                        {t("promoters.discount")}
                      </th>
                      <th className="text-center" scope="col-3">
                        {t("promoters.view")}
                      </th>
                      <th scope="col-3" className="text-right">
                        {t("promoters.actions")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((steward, index) => (
                      <tr key={steward.id}>
                        <td>{`${steward.first_name} ${steward.last_name}`}</td>
                        <td>{steward.email}</td>
                        <td className="text-center">
                          {steward?.discount === "Nenhum"
                            ? t("promoters.none")
                            : steward.discount}
                        </td>
                        <td className="text-center">{steward.visualitions}</td>
                        <td>
                          <div className="card-table-actions">
                            <ReactTooltip
                              className="tooltip-custom"
                              effect="solid"
                              place="top"
                            />
                            <button
                              className="btn-table"
                              onClick={() =>
                                handleStewardModal("modal-show", steward)
                              }
                              data-tip={t("promoters.editPromoter")}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <DropdownButton
                              index={index}
                              itensAmount={data?.length}
                              id={steward?.id}
                              data={steward}
                              element={
                                <button
                                  type="button"
                                  className="btn-table"
                                  onClick={() => handleModalOptions(steward?.id)}
                                >
                                  <FontAwesomeIcon icon={faClone} />
                                </button>
                              }
                              statusIndex={showOptions}
                              onMouseLeave={handleModalOptions}
                              options={options(steward)}
                            />
                            <button
                              className="btn-table"
                              onClick={() => removeSteward(steward.id)}
                              data-tip={t("promoters.deletePromoter")}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  pageConfig={pageConfig}
                  parentFunction={stewardList}
                />
              </div>
            ) : (
              <EmptyData title={t("promoters.noPromoterFound")} />
            )}
          </div>
        </div>
      </div>
      <ModalComissarios
        status={modalStatus}
        event_id={event_id}
        comissario={comissario_id}
        callback={closeModal}
      />
    </Fragment>
  );
};

export default Comissarios;
