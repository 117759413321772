import styled from "styled-components";

export const ToolTipContainer = styled.div`
  position: relative;
  overflow: visible;
  cursor: pointer;
  .tip-data {
    position: absolute;
    width: 240px;
    background-color: #12263f;
    color: #95aac9;
    text-align: left;
    border-radius: 6px;
    padding: 0.8rem 0.95rem;
    z-index: 5;
    bottom: 125%;
    left: 50%;
    margin-left: ${({ isNearLeft }) => isNearLeft ? '-80px' : '-120px'}; 
    transition: opacity 0.3s;
    font-size: 0.75rem;
    font-weight: normal;
    white-space: normal;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    
    
    
    
    
    
    visibility: hidden; /* Hide it initially */
    ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: ${({ isNearLeft }) => isNearLeft ? '-44px' : '-5px'}; 
      border-width: 5px;
      border-style: solid;
      border-color: #12263f transparent transparent transparent;
    }
  }
  :hover {
    .tip-data {
      opacity: 1; /* Make tooltip visible on hover */
      visibility: visible;
    }
  }
`;
